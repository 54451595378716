import React from 'react'
import '../tailwind.css';
import '../stitches.css';

const Nav = () => {
    return (
        <div
            className='font-sans bg-white text-center flex justify-between my-4 mx-auto container overflow-hidden'>
            <a href="/" className="block text-left">
                <img src="/TaRGET_logo.png" className="h-10 sm:h-10" alt="logo"/>
            </a>
            <ul className="text-sm text-grey-dark list-reset flex items-center">
                <li>
                    <a
                        href="/about"
                        className="inline-block py-2 px-3 text-grey-darkest hover:text-grey-dark no-underline">About</a>
                </li>
                {/* <li>
                    <a
                        href="/summary"
                        className="inline-block py-2 px-3 text-grey-darkest hover:text-grey-dark no-underline">Data Summary</a>
                </li> */}
                {/* <li>
                    <a
                        href="https://"
                        className="inline-block py-2 px-3 text-grey-darkest hover:text-grey-dark no-underline">Overview</a>
                </li>*/}

                <li className="pr-2">
                    <a
                        href="/publications"
                        className="inline-block py-2 px-3 text-grey-darkest hover:text-grey-dark no-underline">Publications</a>
                </li>
                <li className="pr-2">
                    <a
                        href="/documents"
                        className="inline-block py-2 px-3 text-grey-darkest hover:text-grey-dark no-underline">Documents</a>
                </li>
                <li>
                    <a
                        href="/software"
                        className="inline-block py-2 px-3 text-grey-darkest hover:text-grey-dark no-underline">Software</a>
                </li>
                <a href="https://submit.targetepigenomics.org">
                    <button
                        className="bg-white border-2 rounded hover:bg-grey-darker hover:text-white ml-4 py-2 px-3">
                        Submission system
                    </button>
                </a>
                <a href="https://target.wustl.edu/wiki">
                    <button className="bg-black hover:bg-grey-darker text-white ml-4 py-2 px-3">
                        Wiki Log In
                    </button>
                </a>

            </ul>
        </div>
    );
}

export default Nav;