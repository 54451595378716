import React from 'react';
import '../tailwind.css';
import '../stitches.css';

const Footer = () => {
    return (
        <div className="font-sans bg-grey-darkest text-white py-8 px-4">
            <div
                className="mx-auto max-w-xl overflow-hidden flex justify-between items-center">
                <ul className="text-sm text-grey-dark list-reset flex items-center">
                    <div className="block mr-4 w-32">
                        <div
                            className='font-bold tracking-wide text-grey-lightest font-sans text-xl font-extrabold'>
                            TaRGET
                        </div>
                    </div>
                    {/* <li><a href="#" className="inline-block py-2 px-3 text-grey hover:text-grey-light no-underline">Product</a></li>
            <li><a href="#" className="inline-block py-2 px-3 text-grey hover:text-grey-light no-underline">Company</a></li>
            <li><a href="#" className="inline-block py-2 px-3 text-grey hover:text-grey-light no-underline">FAQs</a></li> */}
                    <li>
                        <a
                            href="/archive.html"
                            className="inline-block py-2 px-3 text-grey hover:text-grey-light no-underline">Link to Old version</a>
                    </li>
                </ul>
                <p className="inline-block py-2 px-3 text-grey-darker text-xs">
                    ©2018-2020 TaRGET Consortium.</p>
                <div
                    className="text-grey-dark flex flex-col items-center w-full md:w-1/4 mt-6 md:mt-0">
                    <h4
                        className="inline-block py-2 px-3 uppercase font-bold tracking-wide text-grey-lightest">Follow Us</h4>
                    <div className="flex pl-0 md:pl-4 justify-start">
                        <a
                            className="flex items-center text-grey-lightest hover:text-white mr-6 no-underline"
                            href="https://targetdcc.slack.com">
                            <img src="/icons8-slack.svg" alt=""/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;